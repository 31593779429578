.top-form {
  div[class*='MuiOutlinedInput-inputMarginDense'] {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    padding-left: 25px;
    font-size: 12px;
    width: 160px;
  }
}

//remove refresh button from appbar
button[title='Refresh'],
.app-loader {
  display: none;
}

.MuiAppBar-positionFixed {
  transform: none !important;
  transition: none !important;
  visibility: visible !important;
}
